import { ComponentCoreCommissionHeaders } from '@/gql/generated/graphql';
import { Maybe } from 'graphql/jsutils/Maybe';
import React, { Fragment } from 'react'
import ResponsiveImage from './ResponsiveImage';

type Props = {
  commission_headers: Maybe<Array<Maybe<ComponentCoreCommissionHeaders>>>;
}

const CommissionHeader = (props: Props) => {
  return (
    <Fragment>
      <div className="item-list-header flex mb-2">
        <div className="title w-[36%] text-lg text-black text-left pl-8">&nbsp;</div>
        {!!props.commission_headers?.length && 
          props.commission_headers?.map((header, index) => (
            <div key={index} className={`w-[24%] h-[50px] text-center items-center px-1 ${props.commission_headers?.length && (props.commission_headers?.length - 1) === index ? ' flex flex-col' : ''}`}>
              <ResponsiveImage className='inline-block item-header-logo w-auto' image={header?.icon} />
              {header?.description && 
                <span className="text-[13px] text-black">{header?.description}</span>
              }
            </div>
          ))
        }
      </div>
    </Fragment>
  )
}

export default CommissionHeader