import React, { Fragment } from 'react'
import { ComponentCoreCommonTabs, Maybe } from '@/gql/generated/graphql'
import { Tab } from '@headlessui/react'
import RichText from '../renderer/RichText'
import PriceBlock from './Priceblock'

type Props = {
  tabs: Maybe<ComponentCoreCommonTabs>[]
}

export default function CommonTabs(props: Props) {
  if (!props) return null
  return (
    <Fragment>
      <Tab.Group as={Fragment}>
      <div className='tab-wrapper mb-12'>
        <div className='tabs-heading md:flex flex-wrap border-b border-[#895CF8] !p-0 !mb-0 !mt-8'>
          <Tab.List className='tab-heading-list !mb-0 flex flex-wrap items-center justify-center !p-0' as='ul'>
            {props?.tabs?.map((tab, index) => (
              <Tab as='li' key={index} className={({ selected }) => (selected ? 'active bg-[#895CF8] text-white sm:px-4 cursor-pointer' : 'sm:px-4 cursor-pointer')}>
                <div className='tab-title block px-4 py-[10px] font-Lato text-base font-semibold md:text-xl rounded-t-md'>{tab?.title}</div>
              </Tab>
            ))}
          </Tab.List>
        </div>
        <div className='tabs-panel py-6'>
          <div className='tab-content'>
            <Tab.Panels>
              {props?.tabs?.map((tab, index) => {
                return (
                  <Tab.Panel key={index}>
                    {tab?.content &&
                      <div className='tab-intro-content'>
                        <RichText content={tab?.content ?? '{}'} />
                      </div>
                    }
                    <div className='flex flex-wrap sm:flex-nowrap space-y-6 sm:space-y-0 sm:space-x-6 justify-center'>
                      {tab?.priceBlocks?.map((block, index) => (
                          <PriceBlock block={block} key={index}/>
                      ))}
                    </div>
                  </Tab.Panel>
                )
              })}
            </Tab.Panels>
          </div>
          <div className='tab-content hidden'></div>
        </div>
      </div>
    </Tab.Group>
    </Fragment>
  )
}
