import { ComponentBodyAffiliateProgramGroup, Maybe } from '@/gql/generated/graphql'
import React from 'react'
import RichText from '../renderer/RichText'
import AffiliateProgram from '../core/AffiliateProgram'

type Props = {
  data: Maybe<ComponentBodyAffiliateProgramGroup>
}

export default function AffiliateProgramGroup(props: Props) {
  return (
    <section className="affiliate-program-section bg-[#F7F5FE] py-16">
      <div className="container">
        <div className="affiliate-program-group flex flex-wrap justify-center relative after:content-[''] after:bg-[#D9D9D9] after:w-px after:h-[202px]
        after:left-1/2 after:bottom-5 after:absolute after:hidden after:md:block">
          {props.data?.cards?.map((card, index) => (
            <AffiliateProgram key={index} card={card} />
            ))
          }
        </div>
      </div>
    </section>
  )
}
