import { ComponentBodyCommissionSection, Maybe } from '@/gql/generated/graphql'
import React, { Fragment } from 'react'
import ResponsiveImage from './ResponsiveImage'

type Props = {
  data: Maybe<ComponentBodyCommissionSection>
}

const CommissionForMobile = (props: Props) => {
  return (
    <Fragment>
      {/* For Mobile view */}
      <div className="bg-white rounded-[10px] shadow-[0_10px_20px_0_rgba(0,0,0,0.16)] md:hidden">
        <div className="service-item w-full text-left p-5">
          {props.data?.commission_headers?.map((header, header_index) => (
            <Fragment key={header_index}>
              <div className="w-full flex justify-between items-center logo-header">
                <ResponsiveImage className='inline-block' image={header?.icon} />
                {header?.description && 
                  <span className="text-[13px] text-black">{header?.description}</span>
                }                
              </div>

              {props.data?.commission_details?.map((details, index) => (
                <Fragment key={index}>
                  <div className="flex justify-between text-lg text-black pt-5">
                    <div className="text-left pr-4 font-semibold">{details?.title}</div>
                    {header_index === 0 &&
                      <div className="text-right">{details?.service_value_1}</div>
                    }
                    {header_index === 1 &&
                      <div className="text-right">{details?.service_value_2}</div>
                    }
                    {header_index === 2 &&
                      <div className="text-right">{details?.service_value_3}</div>
                    }                    
                  </div>
                </Fragment>
              ))}

            </Fragment>
          ))}
        </div>
      </div>
    </Fragment>
  )
}

export default CommissionForMobile