import { ComponentBodyPricingSection, Maybe } from '@/gql/generated/graphql'
import React from 'react'
import { NEXT_PUBLIC_CDN_URL } from '@/env'
import RichText from '../renderer/RichText'
import PriceBlock from '../core/Priceblock'
import CommonTabs from '../core/CommonTabs'

type Props = {
  data: Maybe<ComponentBodyPricingSection>
}

export default function PricingSection(props: Props) {
  if (!props) return null
  return (
    <section className={`${props.data?.className} subscription-block py-14`}>
      <div className='container'>
        <div className="top-block text-center font-roboto font-semibold text-lg mb-16">
          {props.data?.isBestDeal && 
              <div className="bg-gradient-to-r from-[#8264EB] to-[#D833AE] rounded-[21px] mb-3 font-roboto font-bold text-sm leading-[26px] text-white uppercase inline-block py-0.5 px-[14px]">{props.data.bestDealText}</div>
          }          
          <h2 className="font-roboto font-bold text-3xl leading-[1em] text-black">{props.data?.title}</h2>
          <p>{props.data?.subtitle}</p>
          
          {props.data?.description && 
            <RichText className='details-content' content={props.data?.description ?? '{}'} />
          }
        </div>

        <div className='subscription-container'>
          {props.data?.priceBlocks?.map((priceBlock, index) => (
            <PriceBlock block={priceBlock} key={index}/>
          ))}          
        </div>

        {!!props.data?.tabs?.length &&
          <div className='cloakBox-tab max-w-[860px] mx-auto'>
            <CommonTabs tabs={props.data?.tabs}></CommonTabs>
          </div>
        }

      </div>
    </section>
  )
}
