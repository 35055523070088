import { ComponentBodyClientReviewsCarousel, Maybe } from '@/gql/generated/graphql'
import clsx from 'clsx'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import Carousel, { ButtonGroupProps } from 'react-multi-carousel'
import ResponsiveImage from '../core/ResponsiveImage'

type Props = {
  data: Maybe<ComponentBodyClientReviewsCarousel>
}

type Breakpoint = {
  breakpoint: { max: number; min: number }
  items: number
  slidesToSlide?: number
}
type CarouselBreakpointProps = Record<string, Breakpoint>

export default function ReviewsCarousel(props: Props) {
  const [showingReviews, setShowingReviews] = useState(true)

  const breakpoints: CarouselBreakpointProps = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
      slidesToSlide: 2,
    },
    large: {
      breakpoint: { max: 1024, min: 568 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 568, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  }

  return (
    <section className='review-testmonial mb-[60px] xl:mb-28'>
      <div className='container'>
        <div className='reviews-testimonial relative'>
          <div className='mb-6 flex flex-wrap items-center justify-between gap-4'>
            <h3 className='mb-0 font-roboto text-3xl font-bold'>{props.data?.title}</h3>
            <div className='tab flex justify-end gap-x-8'>
              <button onClick={() => setShowingReviews(true)} className={clsx('tablinks font-roboto text-lg font-semibold', { active: showingReviews })}>
                All Press
              </button>
              <button onClick={() => setShowingReviews(false)} className={clsx('tablinks font-roboto text-lg font-semibold', { active: !showingReviews })}>
                Testimonial
              </button>
            </div>
          </div>

          {showingReviews ? (
            <div id='reviews' className='tabcontent'>
              <Carousel
                customButtonGroup={<CustomNavigationButtons />}
                renderButtonGroupOutside
                itemClass='pr-4'
                responsive={breakpoints}
                swipeable={true}
                arrows={false}
                containerClass="reviews-carousel-container"
              >
                {props.data?.reviews?.map((review, index) => (
                  <div
                    key={review?.id}
                    className='review-item flex min-h-[380px] flex-col justify-between rounded-xl bg-review-bg px-8 py-5 font-roboto text-lg font-normal text-title-color'
                  >
                    <span className='relative block pl-4 text-left'>{review?.review}</span>
                    <div className='review-logo flex flex-wrap justify-center'>
                      {review?.logo?.data ? (
                        <div className='h-[28px]'>
                          <ResponsiveImage image={review?.logo} />
                        </div>
                      ):(
                        <>
                          <div className='w-full text-center font-semibold'>{review?.name}</div>
                          <div className='w-full text-center'>{review?.designation}</div>
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </Carousel>
            </div>
          ) : (
            <div id='testimonial' className='tabcontent'>
              {(props.data?.testimonials?.length as number) > 0 && (
                  <Carousel 
                    customButtonGroup={<CustomNavigationButtons />} 
                    renderButtonGroupOutside 
                    itemClass='pr-4' 
                    responsive={breakpoints} 
                    swipeable={true} 
                    arrows={false}
                    containerClass="testimonial-carousel-container"
                  >
                  {props.data?.testimonials?.map((review, index) => (
                    <div
                      key={review?.id}
                      className='review-item flex min-h-[380px] flex-col justify-between rounded-xl bg-review-bg px-8 py-5 font-roboto text-lg font-normal text-title-color'
                    >
                      <span className='relative block pl-4 text-left'>{review?.review}</span>
                      <div className='review-logo flex flex-wrap justify-center'>
                        {review?.logo?.data ? (
                          <div className='h-[28px]'>
                            <ResponsiveImage image={review?.logo} />
                          </div>
                        ) : (
                          <>
                            <div className='w-full text-center font-semibold'>{review?.name}</div>
                            <div className='w-full text-center'>{review?.designation}</div>
                          </>
                        )}
                      </div>
                    </div>
                  ))}
                </Carousel>
              )}
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

// function CustomDot() {
//   return (
//     <Fragment>
//       <div className='w-10 h-10 bg-black rounded-full'></div>
//     </Fragment>
//   )
// }

function CustomNavigationButtons({ next, previous, carouselState }: ButtonGroupProps) {
  return (
    <div className='pt-12'>
      <div className='arrows-block flex justify-end absolute right-0 -bottom-[23px]'>
        <button
          onClick={previous}
          className='prev arrow w-[50px] h-[50px] border-2 border-[#EFEFEF] block text-[0] rounded-full transition-all hover:border-[#EFEFEF] hover:shadow-[0_0_20px_0_rgba(0,0,0,0.16)]'
        >
          Prev
        </button>
        <button
          onClick={next}
          className='next arrow w-[50px] h-[50px] border-2 border-[#EFEFEF] block text-[0] rounded-full transition-all hover:border-[#EFEFEF] hover:shadow-[0_0_20px_0_rgba(0,0,0,0.16)]'
        >
          Next
        </button>
      </div>
    </div>
  )
}
