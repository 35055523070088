import { ComponentBodyHomeHeroSection, Maybe } from '@/gql/generated/graphql'
import { motion } from 'framer-motion'
import React, { useEffect } from 'react'
import ResponsiveImage from '../core/ResponsiveImage'
import Atropos from 'atropos/react';
import 'atropos/css'
import { NEXT_PUBLIC_CDN_URL} from '@/env'

type Props = {
  hero: Maybe<ComponentBodyHomeHeroSection>
}

export default function HomeHero(props: Props) {
  useEffect(() => {
    function init() {
      const introHero = document.getElementById('intro-hero');
      const introHeroFlex = document.getElementById('intro-hero-flex');
      const mainHeader = document.getElementsByClassName('sticky-outer-wrapper')[0];
      const topSticky = document.getElementsByClassName('top-sticky')[0];
      
      if (introHero){
        introHero.style.paddingTop = `${mainHeader?.clientHeight + 40}px`;
        introHeroFlex?.classList.remove('opacity-0')
        introHeroFlex?.classList.add(...['transition-opacity','duration-500', 'delay-700', 'ease-in','opacity-100'])
      }

      if (topSticky){
        introHero?.classList.add(...['have-top-sticky'])
      }
    }

    if (document.readyState === "loading") {
      document.addEventListener("DOMContentLoaded", init);
    } else {
      init();
    }
    
    return () => {
      document.removeEventListener("DOMContentLoaded", init);
    }
  }, []);

  return (
    <section id="intro-hero" className='intro-hero overflow-x-hidden pb-20 lg:pb-[176px]'>
      <div className='container relative'>
        <div id='intro-hero-flex' className='flex flex-wrap flex-col-reverse lg:flex-row opacity-0 w-full'>
          <div className='w-full lg:w-1/2 relative'>
            <div id='intro-box' className='intro-text-box mb-10 text-2xl text-white md:mb-[92px] hidden md:block'>
              <h1 id='hero-title' className='mb-[30px] font-normal text-white md:text-[32px]'>
                {props.hero?.title}
              </h1>
              <div id='hero-text' className='mb-[40px] text-[36px] leading-[46px] font-bold sm:pr-[94px]' dangerouslySetInnerHTML={{ __html: props.hero?.description ?? '' }}></div>
              <div className='flex items-center gap-x-4'>
                {props.hero?.buttons?.map((button, index) => (
                  <motion.a
                    whileTap={{ scale: 0.9 }}
                    key={button?.id}
                    id='hero-btn'
                    title={button?.label}
                    href={button?.href}
                    className='inline-block font-roboto rounded-[32px] bg-button-color hover:bg-[#6239F7] font-semibold px-[35px] py-[12px] text-center text-xl text-white transition-all duration-1000 hover:opacity-90'
                  >
                    {button?.label}
                  </motion.a>
                ))}
              </div>
            </div>
            <div className='flex flex-wrap gap-y-5 z-10 xl:absolute xl:-bottom-[138px] relative'>
              {props.hero?.products?.map((product, index) => (
                <a
                  title={product?.title || ''}                
                  key={product?.id}
                  
                  role='link'
                  href={product?.href}
                  className='hover-arrow sm:mr-[12px] block sm:h-[210px] sm:w-[210px] w-full h-auto rounded-[12px] bg-[#8264EB] bg-opacity-10 px-5 pb-[64px] pt-6 text-sm text-white transition-all duration-500 hover:bg-opacity-40'
                >
                  <ResponsiveImage className='mb-5' image={product?.logo} />
                  <h3 className='text-base text-white'>{product?.title}</h3>
                  <p>{product?.description}</p>
                </a>
              ))}
            </div>
          </div>
          <div className='w-full pt-5 sm:pt-0 lg:w-1/2 2xl:pl-12 mb-16 lg:mb-0'>
            <div className='intro-text-box mb-20 text-[22px] md:text-2xl text-white md:mb-[92px] md:hidden'>
                <h1 id='hero-title' className='mb-[40px] font-semibold text-white md:text-8xl text-[32px]'>
                  {props.hero?.title}
                </h1>
                <div id='hero-text' className='mb-[40px] sm:pr-[128px]' dangerouslySetInnerHTML={{ __html: props.hero?.description ?? '' }}></div>
                <div className='flex items-center gap-x-4'>
                  {props.hero?.buttons?.map((button, index) => (
                    <motion.a
                      whileTap={{ scale: 0.9 }}
                      key={button?.id}
                      id='hero-mb-btn'
                      role='link'
                      title={button?.label}
                      href={button?.href}
                      className='inline-block rounded-[32px] bg-button-color hover:bg-[#6239F7] px-[35px] py-[12px] text-center text-xl text-white transition-all duration-1000 hover:opacity-90'
                    >
                      {button?.label}
                    </motion.a>
                  ))}
                </div>
            </div>            
            <div className='intro-img-box relative'>
              <ul className='icon-list hidden md:flex gap-x-2 absolute sm:left-0 sm:flex-col sm:gap-x-0 sm:gap-y-3 md:-left-1 md:gap-y-4 lg:gap-y-3 bottom-[16%] left-0'>
                {props.hero?.icons?.map((icon, index) => (
                  <li key={icon?.id} className='w-[46px] sm:w-8 md:w-[46px] lg:w-9 xl:w-[46px]'>
                    <ResponsiveImage className='h-auto w-full' image={icon?.image} />
                  </li>
                ))}
              </ul>                
              <Atropos className="my-atropos">
                <div className='device-img md:mt-[100px] sm:block lg:relative lg:left-[0px]'>
                  <ResponsiveImage isEager={true} className='w-full' image={props.hero?.firstImage} />
                  <ResponsiveImage isEager={true} className='hero-vpn-img absolute h-full sm:h-[120%] md:h-[120%] -top-16 -right-24 sm:-top-20 md:top-[-50px] z-10 inline-block' image={props.hero?.secondImage} />
                </div>
              </Atropos>
              <ul className='icon-list mt-8 sm:mt-0 items-center justify-center flex gap-x-2 md:hidden sm:absolute sm:top-8 sm:left-0 sm:flex-col sm:gap-x-0 sm:gap-y-3 md:top-6 md:-left-1 md:gap-y-4 lg:left-0 lg:top-7 lg:gap-y-3 xl:top-[14px] xl:-left-2.5'>
                {props.hero?.icons?.map((icon, index) => (
                  <li key={icon?.id} className='w-[46px] sm:w-8 md:w-[46px] lg:w-9 xl:w-[46px]'>
                    <ResponsiveImage className='h-auto w-full' image={icon?.image} />
                  </li>
                ))}
              </ul>              
            </div>
          </div>
        </div>
        <div className='anchorblock xl:absolute right-0 -bottom-[140px] z-10 min-w-[200px]'>
            <div className='flex flex-col flex-wrap content-end'>
              <div className='text-[14px] text-white xl:text-[#383C43] text-right'>{props.hero?.testimonialTitle}</div>
              <div className='mr-8'>
                <a className='brand-row' href='#reviews'>
                  {props.hero?.testimonial_icons?.map((icon, index)=> (
                    <div className='brand-item' key={index}>
                      <div role="tooltip" className="tooltip bounce-in">
                        <ResponsiveImage className="min-h-[14px]" image={icon?.popover_icon} />
                      </div>
                      <ResponsiveImage className="w-[48px] h-[48px] rounded" image={icon?.main_icon}/>
                    </div>
                  ))}
                </a>
              </div>
            </div>
          </div>
      </div>
    </section>
  )
}
