import { Maybe, PageBodyDynamicZone } from '@/gql/generated/graphql'
import React, { Fragment } from 'react'
import BlogPostShowcase from '../body/BlogPostShowcase'
import CompanyVision from '../body/CompanyVision'
import ContactCardGroup from '../body/ContactCardGroup'
import AffiliateProgramGroup from '../body/AffiliateProgramGroup'
import CTABanner from '../body/CTABanner'
import PricingPageCTA from '../body/PricingPageCTA'
import FAQSection from '../body/FAQSection'
import GeneralHero from '../body/GeneralHero'
import GlobeSection from '../body/GlobeSection'
import HomeHero from '../body/HomeHero'
import ProductFeatures from '../body/ProductFeatures'
import ProductsAndServices from '../body/ProductsAndServices'
import ProductShowcaseSection from '../body/ProductShowcaseSection'
import ReviewsCarousel from '../body/ReviewsCarousel'
import ReviewsGroup from '../body/ReviewsGroup'
import BodyRichText from '../body/BodyRichText'
import LogoShowcase from '../body/LogoShowcase'
import ColorShowcase from '../body/ColorShowcase'
import ImageGallery from '../body/ImageGallery'
import PricingSection from '../body/PricingSection'
import FullBackgroundImageContent from '../body/FullBackgroundImageContent'
import FeatureContent from '../body/FeatureContent'
import CommissionSection from '../body/CommissionSection'
import AffiliateFeaturesSection from '../body/AffiliateFeaturesSection'

type Props = {
  body: Maybe<PageBodyDynamicZone>
}

export default function BodyRenderer(props: Props) {
  if (!props.body) return null

  if (props.body.__typename === 'ComponentBodyHomeHeroSection') {
    return <HomeHero hero={props.body} />
  }

  if (props.body.__typename === 'ComponentBodyProductShowcaseSection') {
    return <ProductShowcaseSection data={props.body} />
  }

  if (props.body.__typename === 'ComponentBodyGlobeSection') {
    return <GlobeSection data={props.body} />
  }

  if (props.body.__typename === 'ComponentBodyAffiliateProgramGroup') {
    return <AffiliateProgramGroup data={props.body} />
  }

  if (props.body.__typename === 'ComponentBodyCtaBanner') {
    return <CTABanner banner={props.body} />
  }

  if (props.body.__typename === 'ComponentBodyPricingPageCta') {
    return <PricingPageCTA banner={props.body} />
  }

  if (props.body.__typename === 'ComponentBodyRichText') {
    return <BodyRichText data={props.body} />
  }

  if (props.body.__typename === 'ComponentBodyClientReviewsCarousel') {
    return <ReviewsCarousel data={props.body} />
  }

  if (props.body.__typename === 'ComponentBodyBlogPostsShowcase') {
    return <BlogPostShowcase data={props.body} />
  }

  if (props.body.__typename === 'ComponentBodyFaqGroup') {
    return <FAQSection data={props.body} />
  }

  if (props.body.__typename === 'ComponentBodyProductFeaturesSection') {
    return <ProductFeatures data={props.body} />
  }

  if (props.body.__typename === 'ComponentBodyHeroSection') {
    return <GeneralHero hero={props.body} />
  }

  if (props.body.__typename === 'ComponentBodyClientReviewsGroup') {
    return <ReviewsGroup data={props.body} />
  }

  if (props.body.__typename === 'ComponentBodyCompanyVision') {
    return <CompanyVision data={props.body} />
  }

  if (props.body.__typename === 'ComponentBodyProductsAndServicesSection') {
    return <ProductsAndServices data={props.body} />
  }

  if (props.body.__typename === 'ComponentBodyContactCardsGroup') {
    return <ContactCardGroup data={props.body} />
  }

  if (props.body.__typename === 'ComponentBodyLogoShowcase') {
    return <LogoShowcase {...props.body} />
  }

  if (props.body.__typename === 'ComponentBodyColorShowcase') {
    return <ColorShowcase {...props.body} />
  }

  if (props.body.__typename === 'ComponentBodyImageGallery') {
    return <ImageGallery {...props.body} />
  }

  if (props.body.__typename === 'ComponentBodyPricingSection') {
    return <PricingSection data={props.body} />
  }

  if (props.body.__typename === 'ComponentBodyFullBackgroundImageContent') {
    return <FullBackgroundImageContent data={props.body} />
  }
  
  if (props.body.__typename === 'ComponentBodyFeatureContent') {
    return <FeatureContent data={props.body} />
  }

  if (props.body.__typename === 'ComponentBodyCommissionSection') {
    return <CommissionSection data={props.body} />
  }

  if (props.body.__typename === 'ComponentBodyAffiliateFeaturesSection') {
    return <AffiliateFeaturesSection data={props.body} />
  }

  return null
}
