import { ComponentBodyProductsAndServicesSection, Maybe } from '@/gql/generated/graphql'
import { useAutoAnimate } from '@formkit/auto-animate/react'
import React, { Fragment, useState } from 'react'
import ResponsiveImage from '../core/ResponsiveImage'

type Props = {
  data: Maybe<ComponentBodyProductsAndServicesSection>
}

export default function ProductsAndServices(props: Props) {
  const [animateRef] = useAutoAnimate<HTMLDivElement>()
  const [expandedIndex, setExpandedIndex] = useState(0)
  if (!props.data) return null

  return (
    <section className='products-services bg-[#F7F5FE] py-16 md:py-24'>
      <div className='container'>
        <h3 className='mb-16 text-center md:mb-24'>{props.data?.title}</h3>
        <div className='hidden grid-cols-2 items-center gap-14 md:grid lg:gap-40'>
          {props.data.products?.map((product, index) => (
            <Fragment key={product?.id}>
              {index === expandedIndex && (
                <div className='image-block xl:h-[336px]'>
                  <ResponsiveImage className='w-full h-full transition delay-150 duration-300 ease-in-out' image={product?.image} />
                </div>
              )}
            </Fragment>
          ))}
          <div className='text-block flex flex-col gap-y-9'>
            {props.data.products?.map((product, index) => (
              <div ref={animateRef} onClick={() => setExpandedIndex(index)} key={product?.id} className='features-block relative pl-7'>
                <h4 className='cursor-pointer font-semibold text-black'>{product?.title}</h4>
                {index === expandedIndex && <div className='text-lg font-normal text-body-color'>{product?.description}</div>}
              </div>
            ))}
          </div>
        </div>
        <div className='slider md:hidden'>
          {props.data.products?.map((product, index) => (
            <div key={product?.id} className='slider-item px-5 text-center text-lg'>
              <div className='image-block mb-16 text-center'>
                <ResponsiveImage className='inline-block' image={product?.image} />
              </div>
              <h4 className='mb-4 text-center text-2xl'>{product?.title}</h4>
              <p>{product?.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
