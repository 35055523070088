import { ComponentCoreAffiliateFeature, Maybe } from '@/gql/generated/graphql';
import React, { Fragment } from 'react'
import ResponsiveImage from './ResponsiveImage';

type Props = {
  feature?: Maybe<ComponentCoreAffiliateFeature>;
}

const AffiliateFeature = ({feature}: Props) => {
  return (
    <Fragment>
      <li>
        <ResponsiveImage image={feature?.icon} />
        {feature?.description}
      </li>
    </Fragment>
  )
}

export default AffiliateFeature