import { ComponentBodyContactCardsGroup, Maybe } from '@/gql/generated/graphql'
import React from 'react'
import ResponsiveImage from '../core/ResponsiveImage'

type Props = {
  data: Maybe<ComponentBodyContactCardsGroup>
}

export default function ContactCardGroup(props: Props) {
  return (
    <section className='py-12 md:pb-36 md:pt-16'>
      <div className='container'>
        <div className='mb-12 grid grid-cols-1 gap-8 sm:grid-cols-2 md:mb-20 md:grid-cols-3 md:gap-5 lg:mb-36 lg:gap-8'>
          {props.data?.cards?.map((card, index) => (
            <div key={index} className='contact-item rounded-xl bg-[#F7F5FE] p-8 transition-all duration-300 hover:bg-white hover:shadow-[0_20px_40px_0_rgba(0,0,0,0.1)] md:p-5 lg:p-8'>
              <div className='logo-box mb-5'>
                <ResponsiveImage image={card?.icon} />
              </div>
              <h5 className='break-words font-roboto text-xl font-semibold text-black tracking-tight'>{card?.title}</h5>
              <a className='font-roboto text-base font-normal text-[#AD0066] underline hover:no-underline link' href={`mailto:${card?.email}`}>
                {card?.email}
              </a>
            </div>
          ))}
        </div>
        <div className='address text-center font-normal text-lg text-black'>
          <h3 className='mb-6 font-roboto text-3xl font-semibold text-black'>{props.data?.mailingAddress?.title}</h3>
          <p className='leading-loose' dangerouslySetInnerHTML={{ __html: props.data?.mailingAddress?.address ?? '' }}></p>
        </div>
      </div>
    </section>
  )
}
