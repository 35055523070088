import { ComponentBodyCtaBanner, Maybe } from '@/gql/generated/graphql'
import React from 'react'
import RichText from '../renderer/RichText'

type Props = {
  banner: Maybe<ComponentBodyCtaBanner>
}

export default function CTABanner(props: Props) {
  return (
    <section className={`${props.banner?.className ?? ''} joincta mb-[60px]`}>
      <div className='container'>
        <div className='rounded-lg bg-gradient-to-r from-[#5D38F7] to-[#FA8BDC] p-10 text-center xl:px-20 xl:py-12 cta-wrap mx-auto'>
          {props.banner?.title && 
            <h2 dangerouslySetInnerHTML={{ __html: props.banner?.title ?? '' }} className='mb-8 font-roboto text-2xl md:text-4xl font-bold leading-8 md:leading-[46px] text-white'></h2>
          }
          {props.banner?.description && <RichText className='text-white' content={props.banner?.description ?? '{}'} />}
          {props.banner?.buttons && !!props.banner?.buttons.length &&
            <div className='flex items-center justify-center w-full gap-x-4 button-wrap'>
              {props.banner?.buttons?.map((button, index) => (
                <a
                  role="button"
                  key={button?.id}
                  href={button?.href ?? undefined}
                  title={button?.label}
                  className='inline-block rounded-3xl bg-white px-12 py-3 font-roboto text-base font-semibold text-light-purple hover:shadow-[0_10px_20px_0_rgba(0,0,0,0.16)] transition duration-300 hover:bg-light-purple hover:text-white'
                >
                  {button?.label}
                </a>
              ))}
            </div>
          }
        </div>
      </div>
    </section>
  )
}
