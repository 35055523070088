import { ComponentBodyProductShowcaseSection, Maybe } from '@/gql/generated/graphql'
import React, { useState, Fragment } from 'react'
import ResponsiveImage from '../core/ResponsiveImage'
import { Transition } from '@headlessui/react'
import { NEXT_PUBLIC_CDN_URL } from '@/env'

type Props = {
  data: Maybe<ComponentBodyProductShowcaseSection>
}

export default function ProductShowcaseSection(props: Props) {
  if (!props.data) return null
  const [showingProduct, setShowingProduct] = useState<'one' | 'two'>('one')
  return (
    <section className='prowrap bg-[#F7F5FE] pt-10 lg:pt-0 pb-72 sm:pb-80 xl:pb-80'>
      <div className='container'>
        <div className='mx-auto w-full max-w-[972px] pb-10 text-center text-xl font-normal sm:pb-20 lg:pb-20'>
          { props.data.subtext &&
            <span className='block pb-8 text-lg font-bold'>{props.data.subtext}</span>
          }
          <h2 className='mb-8 font-semibold !leading-tight text-3xl lg:text-5xl' dangerouslySetInnerHTML={{ __html: props.data.title ?? '' }}></h2>
          <p>{props.data.description}</p>
        </div>
        <div className='md:flex flex-wrap hidden items-stretch md:pb-10 flex-col-reverse md:flex-row mb-8 md:mb-0'>
          <div className='w-full md:w-1/2 2xl:w-2/5'>
            <div className='mb-10 grid grid-cols-1 gap-y-10 md:mb-0 md:gap-y-14'>
              <div
                onMouseEnter={() => setShowingProduct('one')}
                id='pro-item-1'
                className='pro-item relative h-[260px] max-w-[450px] rounded-[12px] bg-white px-14 md:px-8 lg:px-14 pt-8 pb-[82px] text-base tracking-wide text-title-color transition-all duration-1000 hover:shadow-2xl'
              >
                <ResponsiveImage className='mb-8' image={props.data.productOne?.logo} />
                <p>{props.data.productOne?.description}</p>
                <a href={props.data.productOne?.href} role='button' title={props.data.productOne?.linkLabel} className='btn-arrow absolute md:left-[31px] lg:left-[56px] bottom-[38px] font-semibold'>
                  {props.data.productOne?.linkLabel}
                </a>
              </div>
              <div
                onMouseEnter={() => setShowingProduct('two')}
                id='pro-item-2'
                className='pro-item relative h-[260px] max-w-[450px] rounded-[12px] bg-white px-14 md:px-8 lg:px-14 pt-8 pb-[82px] text-base tracking-wide text-title-color transition-all duration-1000 hover:shadow-2xl'
              >
                <ResponsiveImage className='mb-8' image={props.data.productTwo?.logo} />
                <p>{props.data.productTwo?.description}</p>
                <a href={props.data.productTwo?.href} role='button' title={props.data.productTwo?.linkLabel} className='btn-arrow absolute md:left-[31px] lg:left-[56px] bottom-[38px] font-semibold'>
                  {props.data.productTwo?.linkLabel}
                </a>
              </div>
            </div>
          </div>
          <div className='w-full md:w-1/2 2xl:w-3/5 md:pl-10 lg:pl-0'>
            <div className='pro-img-box ml-auto max-w-[596px] align-top 2xl:h-[654px] overflow-hidden'>
              <Transition.Root show={showingProduct === 'one'} id="pro-img-preview-1" as='div' className='pro-img-item relative'>
                  <ResponsiveImage className='md:w-fit h-[60%] md:h-auto relative -left-16 md:-left-4 md:top-0 top-4' image={props.data.productOne?.firstImage} />
                  <ResponsiveImage className='absolute h-[100%] w-[80%] md:h-[80%] lg:h-auto top-0 md:top-4 right-0 md:right-0 z-10 md:w-fit' image={props.data.productOne?.secondImage} />
              </Transition.Root>
              <Transition.Root show={showingProduct === 'two'} id="pro-img-preview-2" as='div' className='pro-img-item relative mb-32'>
                  <ResponsiveImage className='w-fit' image={props.data.productTwo?.firstImage} />
                  <ResponsiveImage className='absolute -bottom-32 right-0 z-10 w-fit' image={props.data.productTwo?.secondImage} />
              </Transition.Root>
            </div>
          </div>
        </div>
        <div className="pb-20 md:hidden">
          <div className='product-item pb-10'>
            <div className="pro-img-item">
              <img
              className="w-fit"
              src={`${NEXT_PUBLIC_CDN_URL}/images/img-vpn-mobile.svg`}
              alt="personalVPN"
              width='593'
              height='653' 
              />
            </div>
            <div
              className="pro-item relative sm:h-[260px] w-full md:max-w-[450px] rounded-[12px] bg-white px-8 lg:px-14 pt-8 pb-16 text-base tracking-wide text-title-color transition-all duration-1000 hover:shadow-2xl"
            >
              <img
                className="mb-5 lg:mb-8"
                src={`${NEXT_PUBLIC_CDN_URL}/images/logo-vpn.svg`}
                alt="personalVPN"
                width='225'
                height='55'                
              />
              <p>
                Enjoy Secure and Private internet access without any
                geo-restrictions.
              </p>
              <a
                href="https://www.personalvpn.com/"
                
                role="button"
                className="btn-arrow absolute left-[31px] lg:left-[56px] bottom-[38px] font-semibold"
                >Discover personalVPN</a
              >
            </div>            
          </div>          
          <div className='product-item'>
            <div className="pro-img-item">
              <img
              className="w-fit"
              src={`${NEXT_PUBLIC_CDN_URL}/images/img-sme-mobile.svg`}
              alt="SecureMyEmail"
              width='663'
              height='630'                
              />
            </div>
            <div
              className="pro-item relative sm:h-[260px] w-full md:max-w-[450px] rounded-[12px] bg-white px-8 lg:px-14 pt-8 pb-16 text-base tracking-wide text-title-color transition-all duration-1000 hover:shadow-2xl"
            >
              <img
                className="mb-4 lg:mb-6"
                src={`${NEXT_PUBLIC_CDN_URL}/images/logo-sme.svg`}
                alt="SecureMyEmail"
                width='153'
                height='57'                
              />
              <p>
                End-to-End Encryption for ANY email address — personal or
                business. No need to switch email providers.
              </p>
              <a
                href="https://www.securemyemail.com/"
                
                role="button"
                className="btn-arrow absolute left-[31px] lg:left-[56px] bottom-[34px] font-semibold"
                >Discover SecureMyEmail</a
              >
            </div>            
          </div>
        </div>        
        <div className='pro-feature flex flex-wrap items-stretch'>
          <ul className='flex w-full flex-wrap lg:divide-x sm:divide-[#D5D5D5]'>
            {props.data.icons?.map((icon, index) => (
              <li key={icon?.id} className='mb-4 flex w-full items-center justify-start text-lg sm:w-2/4 sm:pr-7 last:pr-0 lg:mb-0 lg:w-1/4'>
                <ResponsiveImage className='mr-6 w-fit' image={icon?.image} />
                <p className='text-center'>{icon?.title}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  )
}


