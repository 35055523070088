import { NEXT_PUBLIC_IMAGE_ENDPOINT } from 'env'
import React, { FC } from 'react'
import { Maybe, UploadFileEntity } from '@/gql/generated/graphql'

type Props = {
  image?: Maybe<UploadFileEntity>
  className?: string
}

const ResponsiveImageForGallery: FC<Props> = (props) => {
  if (!props.image || !props.image) return null
  const link = `${NEXT_PUBLIC_IMAGE_ENDPOINT}/${props.image.attributes?.hash}${props.image.attributes?.ext}`
  const formats = props.image.attributes?.formats
  const attr = props.image.attributes
  const calculateHeightAndWidth = () => {
    if (!props.image) return undefined

    if (props.image.attributes?.ext === '.svg') {
      return { height: attr?.height, width: attr?.width }
    }

    return {
      height: Math.round((attr?.height as number) / 2),
      width: Math.round((attr?.width as number) / 2),
    }
  }

  const processed = calculateHeightAndWidth()

  const generateSrcSet = () => {
    if (props.image && props.image.attributes?.ext === '.svg') return undefined
    const sourceSets: string[] = []
    Object.keys(formats).forEach((res) => {
      const targetImg = formats[res]
      if (res !== 'thumbnail') {
        sourceSets.push(`${NEXT_PUBLIC_IMAGE_ENDPOINT}/${targetImg.hash}${targetImg.ext} ${res}`)
      }
    })

    return sourceSets.join(',')
  }
  return (
    <img
      sizes='70vw'
      alt={attr?.alternativeText ?? ''}
      height={processed?.height ?? 'auto'}
      width={processed?.width ?? 'auto'}
      className={props.className ?? undefined}
      src={link}
      srcSet={generateSrcSet()}
      loading='lazy'
    />
  )
}

export default ResponsiveImageForGallery
