import { ComponentCoreCommissionItems } from '@/gql/generated/graphql';
import { Maybe } from 'graphql/jsutils/Maybe';
import React, { Fragment } from 'react'

type Props = {
  commission_details: Maybe<Array<Maybe<ComponentCoreCommissionItems>>>;
}

const CommissionDetails = (props: Props) => {
  return (
    <Fragment>
      {props.commission_details?.map((commission, index) => (
        <div key={index} className="item-list flex py-[14px]">
          <div className="title w-[36%] text-lg text-black text-left pl-8 font-semibold">{commission?.title}</div>
          <div className="service-one w-[24%] text-center px-4">
            {commission?.service_value_1}
          </div>
          <div className="service-two w-[24%] text-center px-4 flex flex-col items-center">
            {commission?.service_value_2}
          </div>
          <div className="service-three w-[24%] text-center px-4 flex flex-col items-center">
            {commission?.service_value_3}
          </div>          
        </div>
      ))}
    </Fragment>
  )
}

export default CommissionDetails