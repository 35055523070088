import { ComponentBodyBlogPostsShowcase, Maybe } from '@/gql/generated/graphql'
import React from 'react'
import ResponsiveImage from '../core/ResponsiveImage'

type Props = {
  data: Maybe<ComponentBodyBlogPostsShowcase>
}

export default function BlogPostShowcase(props: Props) {
  if (!props.data) {
    return null
  }
  return (
    <section className='blog-article-list mb-[60px] xl:mb-24'>
      <div className='container'>
        <div className='mb-2.5 flex flex-wrap items-center justify-between'>
          <h3 className='font-roboto text-2xl font-bold sm:text-3xl'>{props.data.title}</h3>
          <a
            href={`/blog/category/${props.data.postsFrom?.data?.attributes?.slug}`}
            className='view-all relative mb-2.5 pr-6 font-roboto text-base font-semibold text-light-purple'
            title='View all articles'
          >
            View all articles
          </a>
        </div>
        <div className='-mx-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:divide-x sm:divide-border-color md:grid-cols-3'>
          {props.data.postsFrom?.data?.attributes?.posts?.data.filter(post => post.attributes?.publishedAt).slice(0,3).map((post, index) => (
            <div key={post.id} className='blog-item hidden px-4 md:block'>
              <a href={`/blog/${post.attributes?.slug}`} className='group block sm:mb-5' title={post.attributes?.title}>
                <div className='relative mb-5 overflow-hidden pt-[60%]'>
                  <ResponsiveImage
                    className='absolute left-0 top-0 h-full w-full bg-cover transition-all duration-300 group-hover:scale-110 object-cover'
                    image={post.attributes?.featureImage}
                  />
                </div>
                <h3 className='mb-0 font-roboto text-lg font-semibold transition line-clamp-2 group-hover:text-light-purple md:max-w-[336px] md:min-h-[56px]'>{post.attributes?.title}</h3>
              </a>
              <ul className='cat-list-style flex flex-wrap gap-x-2'>
                {post.attributes?.categories?.data.map((category, index) => (
                  <li key={category.id} className='relative'>
                    <a href={`/blog/category/${category.attributes?.slug}`} role='link' title={category.attributes?.title} className='font-roboto text-sm font-normal hover:text-light-purple hover:underline'>
                      {category.attributes?.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
