import React, { Fragment, useEffect } from 'react'
import { ComponentCorePriceBlock, Enum_Componentcorebutton_Linktype, Enum_Componentcorebutton_Variant, Maybe } from '@/gql/generated/graphql'
import { Enum_Componentcorebutton_Color } from '@/gql/generated/graphql'
import RichText from '../renderer/RichText'
import cookie from 'js-cookie'

type Props = {
  block: Maybe<ComponentCorePriceBlock>
}

export default function PriceBlock(props: Props) {
  if (!props) return null

  useEffect(() => {
    const tapVidCookie = cookie.get('tap_vid')
    const cjCookie = cookie.get('cje')

    if (!tapVidCookie) {
      cookie.remove('tap_ref')
    }

    const stripe_urls = document.getElementsByClassName('stripe_url')
    if (tapVidCookie) {
      cookie.remove('cje')
      cookie.remove('cj_aid')
      cookie.remove('cj_pid')
      Array.from(stripe_urls).forEach((element) => {
        if (element instanceof HTMLAnchorElement) {
          let element_href = new URL(element.href)
          element_href.searchParams.delete('client_reference_id')
          if (!element_href.searchParams.get('client_reference_id')) {
            element_href.searchParams.append('client_reference_id', tapVidCookie)
            element.href = element_href.href
          }
        }
      });
    }

    const ref_urls = document.getElementsByClassName('ref_url')
    const sme_urls = document.getElementsByClassName('sme_url')

    const tapRefCookie = cookie.get('tap_ref')
    if (tapRefCookie) {
      Array.from(ref_urls).forEach((element) => {
        if (element instanceof HTMLAnchorElement) {
          let element_href = new URL(element.href)
          if (element_href.searchParams.get('cjevent')) {
            element_href.searchParams.delete('cjevent')
            element_href.searchParams.delete('AID')
            element_href.searchParams.delete('PID')
          }
          if (!element_href.searchParams.get('ref')) {
            element_href.searchParams.append('ref', tapRefCookie)
            element.href = element_href.href
          }
        }
      });

      Array.from(sme_urls).forEach((element) => {
        if (element instanceof HTMLAnchorElement) {
          let element_href = new URL(element.href)
          if (element_href.searchParams.get('cjevent')) {
            element_href.searchParams.delete('cjevent')
            element_href.searchParams.delete('AID')
            element_href.searchParams.delete('PID')
          }
          if (!element_href.searchParams.get('ref')) {
            element_href.searchParams.append('ref', tapRefCookie)
            element.href = element_href.href
          }
        }
      });
    }

    if (cjCookie) {
      cookie.remove('tap_vid')
      cookie.remove('tap_ref')

      const cj_aid_Cookie = cookie.get('cj_aid')
      const cj_pid_Cookie = cookie.get('cj_pid')
      const cookie_ref_url = cjCookie + '-' + cj_aid_Cookie + '-' + cj_pid_Cookie;

      Array.from(stripe_urls).forEach((element) => {
        if (element instanceof HTMLAnchorElement) {
          let element_href = new URL(element.href)
          element_href.searchParams.delete('client_reference_id')
          if (!element_href.searchParams.get('client_reference_id')) {
            element_href.searchParams.append('client_reference_id', cookie_ref_url)
            element.href = element_href.href
          }
        }
      });

      Array.from(sme_urls).forEach((element) => {
        if (element instanceof HTMLAnchorElement) {
          let element_href = new URL(element.href)
          if (element_href.searchParams.get('ref')) {
            element_href.searchParams.delete('ref')
          }
          if (!element_href.searchParams.get('cjevent')) {
            element_href.searchParams.append('cjevent', cjCookie)
            element_href.searchParams.append('AID', cj_aid_Cookie)
            element_href.searchParams.append('PID', cj_pid_Cookie)
            element.href = element_href.href
          }
        }
      });

      Array.from(ref_urls).forEach((element) => {
        if (element instanceof HTMLAnchorElement) {
          let element_href = new URL(element.href)
          if (element_href.searchParams.get('ref')) {
            element_href.searchParams.delete('ref')
          }
          if (!element_href.searchParams.get('cjevent')) {
            element_href.searchParams.append('cjevent', cookie_ref_url)
            element.href = element_href.href
          }
        }
      });
    }

    return () => { }
  }, [])

  const pushDataLayer = (block: Maybe<ComponentCorePriceBlock>) => {
    if (block?.dataLayerJson) {
      // console.log('pushDataLayer', block?.dataLayerJson)
      let jsonVal = JSON.parse(JSON.stringify(block.dataLayerJson))
      if (jsonVal.length) {
        // @ts-ignore
        jsonVal.forEach(element => {
          // @ts-ignore
          window.dataLayer.push(element);
        });
      } else {
        // @ts-ignore
        window.dataLayer.push(jsonVal);
      }
    }
  }

  return (
    <Fragment>
      <div className={`${props.block?.className ?? ''} ${props.block?.isBestChoice ? 'best-choice' : ''} subscription-box relative bg-white sm:w-[48%] lg:w-1/3 xl:w-full max-w-[370px] sm:mx-[1%] lg:mx-0 shadow-[0_10px_20px_0_rgba(0,0,0,0.16)] rounded-[20px] p-8`}>
        <div className="font-roboto best-choice-top">Best choice</div>
        <div className='flex flex-col h-full justify-between relative subscription-content'>
          <div className='w-full'>
            {props.block?.showFreeBadge &&
              <span className="show-free-badge bg-gradient-to-r from-[#8264EB] to-[#D833AE] mb-4 font-roboto font-bold text-sm text-white uppercase rounded-[7px] inline-block px-5 py-[5px]">Free</span>
            }
            {props.block?.showSaveBadge &&
              <span className="show-save-badge absolute -top-[34px] -right-[26px] bg-[#FCECEC] rounded-[22px] font-roboto font-semibold text-[13px] text-[#AD0066] py-0.5 px-3">{props.block?.saveText}</span>
            }
            {props.block?.showRenewBadge &&
              <span className="renew-text-badge absolute -top-[34px] -right-[26px] bg-[#FCECEC] rounded-[22px] font-roboto font-semibold text-[13px] text-[#AD0066] py-0.5 px-3">{props.block?.renewText}</span>
            }
            <h4 className="font-roboto font-normal text-xl text-black mb-5">{props.block?.title}</h4>
            {props.block?.price &&
              <h3 className={`${props.block?.showFreeBadge ? 'free-title' : ''} font-roboto font-bold text-[40px] leading-[28px] border-b border-[#DEDEE0] pb-8 mb-10`}>{props.block?.price}<span className="font-normal text-2xl leading-[28px]">{props.block?.per}</span>
              </h3>
            }
            {props.block?.content &&
              <RichText className='details-content' content={props.block?.content ?? '{}'} />
            }
          </div>
          <div className='w-full'>
            {props.block?.footerButton &&
              <a
                onClick={()=> pushDataLayer(props.block)}
                key={props.block?.footerButton?.id}
                target={props.block?.footerButton?.linkType == Enum_Componentcorebutton_Linktype.External ? '_blank' : ''}
                href={props.block?.footerButton?.href ?? undefined}
                className={`${props.block?.footerButton?.className ?? ''} ${props.block?.footerButton?.color == Enum_Componentcorebutton_Color.Purple ? 'purple' : 'white'} ${props.block?.footerButton?.variant == Enum_Componentcorebutton_Variant.Bordered ? 'bordard' : 'filled'} subscription-btn font-roboto font-bold text-xl`}
              >
                <span>{props.block?.footerButton?.label}</span>
              </a>
            }
            <div className='min-h-[16px]'>
              {props.block?.footerLine &&
                <h6>{props.block?.footerLine}</h6>
              }
            </div>
            {props.block?.footerContent &&
              <RichText className='footer-content' content={props.block?.footerContent ?? '{}'} />
            }
          </div>
        </div>
      </div>
    </Fragment>
  )
}
