import { ComponentBodyGlobeSection, Maybe } from '@/gql/generated/graphql'
import React from 'react'
import { NEXT_PUBLIC_CDN_URL } from '@/env'

type Props = {
  data: Maybe<ComponentBodyGlobeSection>
}

export default function GlobeSection(props: Props) {
  if (!props) return null
  return (
    <section className="globalvpn relative z-10 -mt-64 bg-center bg-no-repeat py-[60px] xl:py-60">
      <div className='container'>
        <div className='flex flex-wrap'>
          <div className='w-full lg:w-1/2'>
            <div className='text-2xl font-normal text-white sm:pr-20 md:text-4xl md:leading-[48px]'>
              <h3 className='mb-10 text-lg text-[#FA377B]'>{props.data?.subtext}</h3>
              <p dangerouslySetInnerHTML={{ __html: props.data?.title ?? '' }}></p>
              <div className='flex items-center gap-x-4'>
                {props.data?.buttons?.map((button, index) => (
                  <a
                    
                    role='button'
                    key={button?.id}
                    href={button?.href}
                    title={button?.label}
                    className='mt-7 inline-block w-[200px] rounded-[32px] bg-button-color hover:bg-[#6239F7] px-[20px] py-2.5 text-center font-roboto text-xl font-medium text-white transition-all duration-500 hover:opacity-90'
                  >
                    {button?.label}
                  </a>
                ))}
              </div>
            </div>
          </div>
          <div className='w-full md:w-1/2'></div>
        </div>
      </div>
    </section>
  )
}
