import React, { Fragment } from 'react'
import { ComponentCoreAffiliateProgram, Maybe } from '@/gql/generated/graphql'
import { Enum_Componentcorebutton_Color } from '@/gql/generated/graphql'
import ResponsiveImage from './ResponsiveImage'

type Props = {
  card: Maybe<ComponentCoreAffiliateProgram>
}

export default function AffiliateProgram(props: Props) {
  if (!props) return null
  return (
    <Fragment>
      <div className="w-full md:w-1/2 p-5">
        <div className='w-full md:w-[286px] mx-auto text-center'>
          <div className='w-full h-[90px] inline-block mb-5 md:mb-10 text-center'>
            <ResponsiveImage image={props.card?.image} className='inline-block' />
          </div>
        </div>
        <h4 className="font-roboto text-3xl text-black font-semibold text-center mb-0">{props.card?.title}</h4>
        <h3 className="font-roboto text-3xl text-black font-semibold text-center mb-6">{props.card?.subtitle}</h3>
        <div className="button-wrap">
          {props.card?.buttons?.map((button, index) => (
            <div key={index} className='max-w-[286px] mx-auto'>
            {button?.color === Enum_Componentcorebutton_Color.White ?
                <a href={button?.href ?? '#'} className="arrow-btn font-roboto font-semibold text-lg text-[#8264EB] border border-[#8264EB] rounded-3xl 
                w-full h-12 inline-block text-center leading-[48px] hover:bg-[#8264EB] hover:text-white mb-2.5">{button.label}</a>
                :
                button?.color === Enum_Componentcorebutton_Color.Purple &&
                <a href={button?.href ?? '#'} className="fill-btn bg-[#8264EB] font-roboto font-semibold text-lg text-white border border-[#8264EB] rounded-3xl 
                w-full h-12 inline-block text-center leading-[48px] mb-2.5 hover:bg-white hover:text-[#8264EB]">{button.label}</a>
            }
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  )
}
