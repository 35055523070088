import React, { FC, Fragment } from "react";
import { ComponentBodyColorShowcase, Maybe } from '@/gql/generated/graphql';

type Props = Maybe<ComponentBodyColorShowcase>;

const ColorShowcase: FC<Props> = (props) => {
  return (
    <Fragment>
      <section
        style={props?.customStyles}
        className={`${props?.className ?? ''} colorShowcase`}
      >
        <div className="container">
          <div className="mx-auto mb-20 max-w-[970px]">
            <h4 className="mb-5 font-roboto font-semibold text-title-color text-center">
              {props?.title}
            </h4>
            <ul className="brand-item-list text-center">
              {props?.colors?.map((color) => (
                <li
                  key={color?.colorCode}
                  style={{ backgroundColor: color?.colorCode ?? "" }}
                  className="m-2 inline-block h-[49px] w-[95%] rounded-none text-center font-roboto text-xl font-semibold leading-[49px] text-white sm:w-[150px]"
                >
                  {color?.colorCode ?? ""}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default ColorShowcase;
