import React from 'react'
import { ComponentBodyFeatureContent, Maybe } from '@/gql/generated/graphql'
import RichText from '../renderer/RichText'
import ResponsiveImage from '../core/ResponsiveImage'
import clsx from 'clsx'

type Props = {
  data: Maybe<ComponentBodyFeatureContent>
}

const FeatureContent = (props: Props) => {

  const data = props.data
  
  return (
    <section className={clsx(`feature-content ${props.data?.ClassNames}`, { 'bg-gray-light': data?.BackgroundGray })}>
      <div className="container">
        <div className={clsx('flex flex-wrap items-center', {'right-side-image': !data?.ImagePositionLeft})}>
          <div className="w-full text-lg font-normal leading-[24px]">
            {data?.FullWidthTitle && 
            <h2 className="md:text-[36px] text-2xl font-semibold md:leading-[40px] mb-[32px]">{data.FullWidthTitle}</h2>
            }
            {data?.FullWidthSubTitle && 
              <RichText content={data.FullWidthSubTitle ?? '{}'} />
            } 
          </div>
          {data?.FeatureImage && 
            <div className="w-full md:w-1/2 mb-10 md:mb-0 md:px-4">
              <div className="justify-center flex">
                <ResponsiveImage className='md:h-[390px] h-auto' image={data?.FeatureImage}/>
              </div>
            </div>
          }
          <div className="w-full md:w-1/2">
            <div className="text-lg font-normal leading-[24px] md:max-w-[480px]">
              {data?.Title && 
                <h2 className="md:text-[36px] text-3xl font-semibold md:leading-[40px] mb-[32px] font-roboto">{data.Title}</h2>
              }
              {data?.Content &&
                <RichText className='block-content' content={data.Content ?? '{}'} />
              }
            </div>
          </div>
          {data?.FooterText &&
            <div className="w-full text-lg font-normal leading-[24px] my-8">
              <RichText className='prose-p:m-0' content={data.FooterText ?? '{}'} />
            </div>
          }
        </div>
      </div>
    </section>
  )
}

export default FeatureContent