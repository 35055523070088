import { ComponentBodyClientReviewsGroup, Maybe } from '@/gql/generated/graphql'
import React from 'react'
import ResponsiveImage from '../core/ResponsiveImage'
import { NEXT_PUBLIC_CDN_URL } from '@/env'

type Props = {
  data: Maybe<ComponentBodyClientReviewsGroup>
}

export default function ReviewsGroup(props: Props) {
  if (!props.data) return null
  return (
    <section className='our-clients relative mb-[60px]'>
      {props.data.backgroundImage?.data &&
        <div className='bg-img left-0 top-0 right-0 bottom-0 -z-10'>
          <ResponsiveImage className='h-full w-full object-cover' image={props.data.backgroundImage}/>
        </div>
      }
      {props.data.reviews && props.data.reviews.length > 0 &&  
        <div className='container'>
          <h3 className='mb-[75px] text-center text-white'>{props.data.title}</h3>
          <div className='grid grid-cols-1 gap-5 md:grid-cols-3 md:gap-8 lg:px-10 xl:px-[75px]'>
            {props.data.reviews?.map((review, index) => (
              <div key={review?.id} className='client-block flex flex-col justify-between rounded-[20px] bg-white p-5 text-center lg:py-10 lg:px-8'>
                <div>
                  <div className='client-logo mb-11 flex justify-center'>
                    <ResponsiveImage className='w-16 h-16' image={review?.logo} />
                  </div>
                  <span className='mb-10 block text-lg text-title-color line-clamp-4'>“{review?.review}”</span>
                </div>
                <div>
                  <h6 className='m-0 text-base font-semibold text-title-color'>{review?.name}</h6>
                  <span className='block text-base font-normal text-[#8A8A8A]'>{review?.designation}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      }
    </section>
  )
}
