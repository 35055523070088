import { ComponentBodyAffiliateFeaturesSection, Maybe } from '@/gql/generated/graphql'
import React from 'react'
import RichText from '../renderer/RichText'
import ResponsiveImage from '../core/ResponsiveImage'
import AffiliateFeature from '../core/AffiliateFeature'

type Props = {
  data: Maybe<ComponentBodyAffiliateFeaturesSection>
}

const AffiliateFeaturesSection = (props: Props) => {
  return (
    <section className="why-wiTopia-affiliate bg-[#F7F5FE] text-center pt-10 md:pt-16 pb-10 md:pb-12">
      <div className="container">
        <h3 className="font-semibold text-[#252525] mb-8">{props.data?.title}</h3>
        {props.data?.description && 
          <RichText className='details-content' content={props.data?.description ?? '{}'} />
        }
        {!!props.data?.features?.length && 
          <ul>
            {props.data?.features?.map((feature, index) => (
              <AffiliateFeature key={index} feature={feature} />
            ))}
          </ul>
        }
      </div>
    </section>
  )
}

export default AffiliateFeaturesSection