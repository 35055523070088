import { ComponentBodyFaqGroup, Maybe } from '@/gql/generated/graphql'
import React from 'react'
import * as Accordion from '@radix-ui/react-accordion'
import FaqItem from '../core/FaqItem'
// import FaqLd from '../jsonLd/FaqLd'

type Props = {
  data: Maybe<ComponentBodyFaqGroup>
}

export default function FAQSection(props: Props) {
  return (
    <>
      {/* <FaqLd data={props.data} /> */}
      <section className={`${props.data?.className ?? ''} faq mb-[60px]`}>
        <div className='container'>
          <h3 className='mb-0 text-center font-roboto text-3xl font-bold md:leading-[50px] md:mb-6 lg:mb-10'>{props.data?.title}</h3>
          <Accordion.Root type='multiple' asChild>
            <div className='accordion-block mx-auto max-w-[748px]'>
              {props.data?.entries?.map((entry, index) => (
                <FaqItem faq={entry} key={entry?.id} />
                ))}
            </div>
          </Accordion.Root>
        </div>
      </section>
    </>
  )
}
