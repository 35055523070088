import { ComponentBodyCompanyVision, Maybe } from '@/gql/generated/graphql'
import React from 'react'
import ResponsiveImage from '../core/ResponsiveImage'
import RichText from '../renderer/RichText'

type Props = {
  data: Maybe<ComponentBodyCompanyVision>
}

export default function CompanyVision(props: Props) {
  if (!props.data) return null
  return (
    <section className='about-content prose-h3:mb-8 prose-h4:mb-4 prose-h4:text-2xl prose-h4:font-normal prose-h5:text-2xl prose-h5:font-semibold pt-12 pb-8'>
      <div className='container'>
        <div className='mx-auto max-w-[820px] text-center font-roboto text-xl font-normal text-body-color'>
          <h3>{props.data.title}</h3>
          <h4>{props.data.description}</h4>
          <h5>{props.data.subtitle}</h5>
          <div className='my-20 gap-y-10 flex flex-col'>
            {props.data.icons?.map((icon, index) => (
              <div key={icon?.id} className='md:flex md:gap-x-10 text-center md:text-left items-center'>
                <ResponsiveImage className='mb-5 md:mb-0 w-[40px] h-[40px] mx-auto md:mx-0' image={icon?.image} />
                {icon?.title}
              </div>
            ))}
          </div>
          <RichText className='prose-p:text-lg prose-p:m-0 prose-strong:text-lg content' content={props.data.content ?? '{}'} />
        </div>
      </div>
    </section>
  )
}
