import React from 'react'
import * as Accordion from '@radix-ui/react-accordion'
import { ComponentCoreSingleFaqItem, Maybe } from '@/gql/generated/graphql'
import RichText from '../renderer/RichText'
import { useAutoAnimate } from '@formkit/auto-animate/react'
import CommonTabs from './CommonTabs'

type Props = {
  faq: Maybe<ComponentCoreSingleFaqItem>
}

export default function FaqItem(props: Props) {
  const [animateRef] = useAutoAnimate<HTMLDivElement>()
  return (
    <Accordion.Item asChild value={props.faq?.id ?? ''}>
      <div ref={animateRef} className='border-b border-gray-300'>
        <Accordion.Trigger asChild>
          <button className='accordion-btn relative w-full py-4 pr-8 text-left font-roboto text-xl font-semibold leading-9 cursor-pointer'>{props.faq?.question}</button>
        </Accordion.Trigger>
        <Accordion.Content asChild>
          <div className='font-roboto text-lg font-normal'>
            {props.faq?.answer && props.faq?.answer.length > 0 && 
            
              <RichText className='prose-p:text-[18px] prose-p:font-normal' content={props.faq?.answer} />
            }
            
            {(props.faq?.tabs && props.faq?.tabs?.length > 0)  && 
              <div className='accordionTabWrap'>
                <CommonTabs tabs={props.faq?.tabs}></CommonTabs>
              </div>
            }
          </div>
        </Accordion.Content>
      </div>
    </Accordion.Item>
  )
}
