import { ComponentBodyCommissionSection, Maybe } from '@/gql/generated/graphql'
import React from 'react'
import CommissionHeader from '../core/CommissionHeader'
import CommissionDetails from '../core/CommissionDetails'
import RichText from '../renderer/RichText'
import CommissionForMobile from '../core/CommissionForMobile'

type Props = {
  data: Maybe<ComponentBodyCommissionSection>
}

export default function CommissionSection(props: Props) {
  return (
    <section className="how-much bg-gradient-to-r from-[#9B89FA] to-[#F0BBF4] pt-10 md:pt-16 pb-14 md:pb-20 text-center">
      <div className="container">
        <h3 className="font-semibold text-white mb-6">{props.data?.title}</h3>
        {props.data?.description &&
          <RichText className='details-content' content={props.data?.description ?? '{}'} />
        }
        <div className='md:px-[100px]'>
          <div className="w-full mx-auto bg-white rounded-[10px] shadow-[0_10px_20px_0_rgba(0,0,0,0.16)] hidden md:block">
            <div className="item-list-wrap pt-7 pb-3">
              <CommissionHeader commission_headers={props.data?.commission_headers} />
              <CommissionDetails commission_details={props.data?.commission_details} />
            </div>
          </div>
        </div>

        <CommissionForMobile data={props.data} />
      </div>
    </section>
  )
}
