import { ComponentBodyPricingPageCta, Maybe } from '@/gql/generated/graphql'
import React from 'react'
import RichText from '../renderer/RichText'

type Props = {
  banner: Maybe<ComponentBodyPricingPageCta>
}

export default function PricingPageCTA(props: Props) {
  return (
    <section className={`purple-cta ${props.banner?.className} mb-10`}>
      <div className="container">
        <div className="cta-block rounded-2xl bg-gradient-to-r from-[#5D38F7] to-[#C25CE2] px-4 md:px-9 py-6 text-center lg:text-left lg:flex justify-between items-center">
          <div className="max-w-[700px] font-roboto font-normal text-white text-lg mb-4 lg:mb-0">
            {props.banner?.title &&
              <h3 className="font-roboto font-medium text-white text-3xl m-0">{props.banner?.title}</h3>
            }
            {props.banner?.description && <RichText className='text-white' content={props.banner?.description ?? '{}'} />}
          </div>
          {props.banner?.buttons?.map((button, index) => (
            <a
              role="button"
              key={button?.id}
              href={button?.href ?? undefined}
              title={button?.label}
              className='inline-block max-w-[210px] rounded-3xl bg-white px-8 py-3 font-roboto text-base font-semibold text-light-purple hover:shadow-[0_10px_20px_0_rgba(0,0,0,0.16)] transition duration-300 hover:bg-light-purple hover:text-white'
            >
              {button?.label}
            </a>
          ))}
        </div>
      </div>
    </section>
  )
}
