import { ComponentBodyHeroSection, Enum_Componentcorebutton_Linktype, Maybe } from '@/gql/generated/graphql'
import React, {useEffect} from 'react'
import RichText from '../renderer/RichText'

type Props = {
  hero: Maybe<ComponentBodyHeroSection>
}

export default function GeneralHero(props: Props) {

  useEffect(() => {
    function init() {
      const generalHero = document.getElementById('general-hero');
      const generalHeroFlex = document.getElementById('general-hero-container');
      const mainHeader = document.getElementsByClassName('sticky-outer-wrapper')[0]
  
      if (generalHero) {
        generalHero.style.paddingTop = `${mainHeader?.clientHeight + 40}px`;
        generalHeroFlex?.classList.remove('opacity-0')
        generalHeroFlex?.classList.add(...['transition-opacity', 'duration-500', 'delay-700', 'ease-in', 'opacity-100'])
      }
    }

    if (document.readyState === "loading") {
      document.addEventListener("DOMContentLoaded", init);
    } else {
      init();
    }
    return () => {
      document.removeEventListener("DOMContentLoaded", init);
    }
  }, []);

  return (
    <section id='general-hero' className={`${props.hero?.className !== null ? props.hero?.className : ''} inner-banner text-center md:min-h-[350px] flex items-center pb-10 lg:pb-20`}>
      <div id='general-hero-container' className='container opacity-0'>
        <h1 className='mx-auto max-w-[840px] font-roboto font-semibold leading-[1.16em] text-white text-3xl md:text-5xl'>{props.hero?.title}</h1>
        {props.hero?.description && <RichText className='text-white content-hero' content={props.hero?.description ?? '{}'} />}
        {!!props.hero?.heroButtons?.length &&
          <div className='mt-[30px] flex flex-col items-center justify-center gap-4 lg:flex-row'>
            {props.hero?.heroButtons?.map((button) => (
              <a
                key={button?.id}
                href={button?.href ?? undefined}
                className={`${button?.className ?? ''} border-spacing-2 rounded-full border-2 border-solid border-white py-2 px-6 font-roboto text-xl font-semibold text-white hover:border-[#8264EB] hover:bg-[#8264EB] hover:shadow-[0_10px_20px_0_rgba(0,0,0,0.16)]`}        
              >
                <span>{button?.label}</span>
              </a>
            ))} 
          </div>
        }
      </div>
    </section>
  )
}
