import { ComponentBodyFullBackgroundImageContent, Maybe } from '@/gql/generated/graphql'
import React, { useEffect } from 'react'
import RichText from '../renderer/RichText'
import ResponsiveImage from '../core/ResponsiveImage'

type Props = {
  data: Maybe<ComponentBodyFullBackgroundImageContent>
}

export default function FullBackgroundImageContent(props: Props) {
  useEffect(() => {
    function init() {
      const calculatedPad = document.getElementById('calculated-pad');
      const mainHeader = document.getElementsByClassName('sticky-outer-wrapper')[0]

      if (calculatedPad !== undefined) {
        // @ts-ignore
        mainHeader.style.position = 'relative';
        // calculatedPad.style.marginTop = `${mainHeader?.clientHeight}px`;
      }
    }

    if (document.readyState === "loading") {
      document.addEventListener("DOMContentLoaded", init);
    } else {
      init();
    }
    
    return () => {
      document.removeEventListener("DOMContentLoaded", init);
    }
  }, []);

  return (
    <section id="calculated-pad" className={`relative ${props.data?.classNames ?? ''}`}>
      {props.data?.backgroundImage?.data &&   
        <div className="w-full h-[440px] sm:h-auto">
          <ResponsiveImage className='h-full object-cover w-full' image={props.data.backgroundImage} />
        </div>
      }
      <div className="container thank-you-content absolute left-1/2 top-0 -translate-x-1/2
        text-center py-12 md:py-20 lg:py-28 font-roboto font-normal text-lg text-white leading-[1.1em]">
        {props.data?.titleText && 
          <RichText className='font-roboto font-bold xl:text-6xl text-white xl:leading-[1.16em] mb-5' content={props.data?.titleText ?? '{}'} />
        }
        {props.data?.subTitle && 
          <h3 className="font-roboto font-semibold text-white mb-0 text-2xl md:text-3xl leading-[1.06em]">{props.data?.subTitle}</h3>
        }
        {props.data?.description && 
          <RichText className='details-content' content={props.data?.description ?? '{}'} />
        }
      </div>
    </section>
  )
}
