import {
  ComponentBodyProductFeaturesSection,
  Enum_Componentbodyproductfeaturegroup_Textposition,
  Enum_Componentcorebutton_Variant,
  Enum_Componentcoreproductfeature_Imageposition,
  Maybe,
} from '@/gql/generated/graphql'
import clsx from 'clsx'
import React, { Fragment } from 'react'
import ResponsiveImage from '../core/ResponsiveImage'

type Props = {
  data: Maybe<ComponentBodyProductFeaturesSection>
}

export default function ProductFeatures(props: Props) {
  return (
    <section className='personalvpn-scroll pt-[60px] xl:pt-20 2xl:block'>
      <div className='container'>
        <div className='w-full pb-16 text-center'>
          <h2 className='text-3xl xl:text-5xl'>{props.data?.title}</h2>
        </div>
        {props.data?.groups?.map((group, index) => (
          <Fragment key={group?.id}>
            <div className={clsx('flex w-full flex-wrap pb-16', { 'flex-row-reverse': group?.textPosition === Enum_Componentbodyproductfeaturegroup_Textposition.Right })}>
              <div className='w-full text-left text-lg md:w-1/2'>
                <h3 className='text-3xl xl:text-5xl'>{group?.title}</h3>
                <p>{group?.subtext}</p>
              </div>
              <div className='w-full text-left text-lg md:w-1/2'></div>
            </div>
            <div className='item-list md:flex md:flex-wrap'>
              {group?.features?.map((feature, index) => (
                <Fragment key={feature?.id}>
                  <div
                    className={clsx('item mb-12 flex w-full flex-wrap md:min-h-[388px]', {
                      'flex-row-reverse': feature?.imagePosition === Enum_Componentcoreproductfeature_Imageposition.Left,
                    })}
                  >
                    <div className='h-full w-full md:w-1/2'>
                      <div className='relative mb-12 flex h-full'>
                        <div className='gray-bar absolute inset-0 flex h-full w-10 items-center justify-center'>
                          <div className='pointer-events-none h-full w-[1px] bg-gray-200'></div>
                        </div>
                        <div className='relative z-10 inline-flex h-[60px] w-10 flex-shrink-0 items-center justify-center rounded-full bg-white pb-10 text-white'>
                          <ResponsiveImage image={feature?.logo} />
                        </div>
                        <div className='-mt-2 flex-grow pl-4'>
                          <div className='flex flex-wrap'>
                            <div className='w-full pr-14'>
                              <h3 dangerouslySetInnerHTML={{ __html: feature?.title ?? '' }} className='text-2xl font-medium'></h3>
                              <p className='leading-relaxed'>{feature?.description}</p>
                            </div>
                            <div className='btn-wrap flex flex-wrap gap-2.5 mt-12'>
                              {feature?.buttons?.map((button, index) => (
                                <a key={button?.id} aria-label={button?.label} role='button' title={button?.label} href={button?.href} className={button?.variant === Enum_Componentcorebutton_Variant.Bordered ? 'btn-light' : 'btn-primary'}>
                                  {button?.label}
                                </a>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='h-full w-full md:w-1/2 md:justify-end justify-center flex md:block'>
                      <ResponsiveImage
                        className={clsx('float-right w-fit', { 'float-left': feature?.imagePosition === Enum_Componentcoreproductfeature_Imageposition.Left })}
                        image={feature?.image}
                      />
                    </div>
                  </div>
                </Fragment>
              ))}
            </div>
          </Fragment>
        ))}
      </div>
    </section>
  )
}
