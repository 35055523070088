import React, { FC, Fragment } from 'react'
import { NextSeo } from 'next-seo'
import { NEXT_PUBLIC_IMAGE_ENDPOINT } from 'env'
import { ComponentSharedSeo, Maybe } from '@/gql/generated/graphql'

type Props = {
  data: Maybe<ComponentSharedSeo> | undefined
  slug: Maybe<string> | undefined
  title: Maybe<string> | undefined
}

const PageSeo: FC<Props> = (props) => {
  const slug = props.slug ?? ''
  const canonicalURLFromSlug = 'https://www.witopia.com/' + slug
  
  if (!props.data){
    return (
      <Fragment>
        <NextSeo
          title={props.title ?? ''}
          canonical={canonicalURLFromSlug}
          openGraph={{
            title: props.title ?? '',
          }}
        />
      </Fragment>
    )
  }

  const imageUrl = props.data?.metaImage?.data?.attributes?.ext === '.svg' ? props.data?.metaImage?.data?.attributes?.url : props.data?.metaImage?.data?.attributes?.formats['1080w'].url

  return (
    <Fragment>
      <NextSeo
        title={props.data.metaTitle}
        description={props.data.metaDescription}
        canonical={props.data.canonicalURL ? props.data.canonicalURL : canonicalURLFromSlug}
        openGraph={{
          title: props.data.metaTitle,
          description: props.data.metaDescription,
          images: [{ url: imageUrl || '' }]
        }}
        twitter={{
          cardType: "summary_large_image"
        }}
        additionalMetaTags={[{
          property: 'twitter:image',
          content: imageUrl || ''
        }]}
      />
    </Fragment>
  )
}

export default PageSeo
