import React, { FC, Fragment } from 'react'
import { ComponentBodyImageGallery, Enum_Componentcorebutton_Linktype, Enum_Componentcoreimageshowcase_Imagespercolumn, Maybe } from '@/gql/generated/graphql'
import { Tab } from '@headlessui/react'
import ResponsiveImageForGallery from 'components/core/ResponsiveImageForGallery'

type Props = Maybe<ComponentBodyImageGallery>

const ImageGallery: FC<Props> = (props) => {
  const decideColCount = (count?: Maybe<Enum_Componentcoreimageshowcase_Imagespercolumn>) => {
    if (!count) return 'lg:grid-cols-5'
    switch (count) {
      case Enum_Componentcoreimageshowcase_Imagespercolumn.Two:
        return 'lg:grid-cols-2'
      case Enum_Componentcoreimageshowcase_Imagespercolumn.Four:
        return 'lg:grid-cols-4'
      case Enum_Componentcoreimageshowcase_Imagespercolumn.Three:
        return 'lg:grid-cols-3'
      default:
        return 'lg:grid-cols-5'
    }
  }
  return (
    <Fragment>
      <section style={props?.customStyles} className={`${props?.className ?? ''} imageGallery`}>
        <div className='container'>
          <div className='mx-auto mb-6 max-w-[970px]'>
            <h4 className='font-roboto font-semibold text-title-color text-center'>{props?.title}</h4>
            <Tab.Group as={Fragment}>
              <div className='tab-wrapper mb-12'>
                <div className='flex flex-wrap justify-center items-center !p-0 !mb-0 !mt-8 !list-none'>
                  <Tab.List className='tab-heading-list !mb-0 flex !list-none flex-wrap items-center justify-center !p-0' as='ul'>
                    {props?.tabs?.map((tab, index) => (
                      <Tab as='li' key={index} className={({ selected }) => (selected ? 'underline sm:px-4 cursor-pointer' : 'sm:px-4 cursor-pointer')}>
                        <div className='block py-1 px-3 font-roboto text-base font-semibold text-black no-underline hover:text-[#8264EB] hover:underline'>{tab?.title}</div>
                      </Tab>
                    ))}
                  </Tab.List>
                </div>
                <div className='tabs-panel py-6'>
                  <div className='tab-content'>
                    <Tab.Panels>
                      {props?.tabs?.map((tab, index) => {
                        return (
                          <Tab.Panel key={index}>
                            <ul className={`grid grid-cols-1 sm:grid-cols-2 ${decideColCount(tab?.imagesPerColumn)} justify-items-center gap-5`}>
                              {tab?.images?.data.map((image, index) => (
                                <li key={index}>
                                  <ResponsiveImageForGallery className='w-full' image={image} />
                                </li>
                              ))}
                            </ul>
                          </Tab.Panel>
                        )
                      })}
                    </Tab.Panels>
                  </div>
                  <div className='tab-content hidden'></div>
                </div>
              </div>
            </Tab.Group>
            <div className='flex items-center gap-x-4 justify-center mt-4'>
              {props?.buttons?.map((button, index) => (
                <a
                  key={index}
                  href={button?.href}
                  className='rounded-3xl bg-[#7553ec] py-2.5 inline-block px-5 font-roboto text-base font-semibold text-white shadow-[0_10px_15px_0px_rgba(0,0,0,0.16)] hover:bg-[#6239F7] sm:px-9 sm:text-lg'
                >
                  {button?.label}
                </a>
              ))}
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  )
}

export default ImageGallery
