import { Menus } from '@/common/types'
import { PromoEntity } from '@/gql/generated/graphql'
import React, { Fragment } from 'react'
import Footer from './Footer'
import MainMenu from './MainMenu'

type Props = {
  menus: Menus
  promos: PromoEntity[]
  children: any
  isWhiteTheme?: boolean
  hideLocales?: boolean
  notSticky?: boolean
}

export default function RootLayout(props: Props) {
  return (
    <Fragment>
      <MainMenu promos={props.promos} notSticky={props.notSticky} isWhiteTheme={props.isWhiteTheme} hideLocales={true} menu={props.menus.menu} />
      {props.children}
      <Footer menu={props.menus.footer} />
    </Fragment>
  )
}
