import ResponsiveImage from "components/core/ResponsiveImage";
import React, { FC, Fragment } from "react";
import { ComponentBodyLogoShowcase, Enum_Componentcorebutton_Linktype, Maybe } from '@/gql/generated/graphql';

type Props = Maybe<ComponentBodyLogoShowcase>

const LogoShowcase: FC<Props> = (props) => {
  if (!props) return null;
  return (
    <Fragment>
      <section
        style={props.customStyles}
        className={`${props?.className ?? ''} py-[50px] lg:pt-[100px] lg:pb-16 logoShowcase`}
        
      >
        <div className="container">
          <div className="text-center">
            <h2 className="mb-5 font-roboto font-semibold text-title-color">
              {props.title}
            </h2>
            <h4 className="mb-5 font-roboto font-semibold text-title-color">
              {props.subtitle}
            </h4>
            {/*<h5 className="font-Lato font-medium text-base leading-[1.2em] text-body-color uppercase border-b border-[0_0_0_0.1] mb-9 pb-4">
              //{props.subtext}
            </h5>*/
            }
            <div className="mx-auto max-w-[970px]">
              <div className="mb-14 grid grid-cols-1 gap-8 gap-y-7 lg:grid-cols-3">
                {props.logos?.map((logo, index) => (
                  <div
                    style={{ backgroundColor: logo?.backgroundColor ?? "" }}
                    key={index}
                    className="logo-box relative rounded-[15px] border border-[#E8E8E8] pt-[216px] lg:pt-[70%]"
                  >
                    <div className="absolute left-0 top-0 right-0 bottom-0 flex items-center justify-center px-5">
                      <ResponsiveImage image={logo?.logo} />
                    </div>
                  </div>
                ))}
              </div>

              {props.buttons?.map((button, index) => (
                <a
                  key={index}
                  href={button?.href}
                  className='rounded-3xl bg-[#7553ec] py-2.5 inline-block px-5 font-roboto text-base font-semibold text-white shadow-[0_10px_15px_0px_rgba(0,0,0,0.16)] hover:bg-[#6239F7] sm:px-9 sm:text-lg'
                  >
                  {button?.label}
                </a>
              ))}

            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default LogoShowcase;
