import { ComponentBodyRichText, Maybe } from '@/gql/generated/graphql'
import React from 'react'
import RichText from '../renderer/RichText'

type Props = {
  data: Maybe<ComponentBodyRichText>
}

export default function BodyRichText(props: Props) {
  if (!props.data) return null
  return (
    <section className='affiliate-content py-16 prose-h3:mb-12 prose-h3:text-center prose-h3:font-semibold prose-ul:list-disc prose-li:mb-5 md:pt-28 md:pb-24'>
      <div className='container'>
        <div className='mx-auto max-w-[990px] font-roboto text-xl font-medium text-body-color md:px-32'>
          {props.data.content &&
            <RichText content={props.data.content ?? '{}'} />
          }
        </div>
      </div>
      
    </section>
  )
}
